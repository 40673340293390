<template>
    <div>
        <div class="marketo-form">
            <Typography class="title" variant="h3-display" as="h3">Sign up for emails from the Carnegie Global Order and Institutions Program.</Typography>
            <Typography class="description" variant="body-display-small" as="p">{{ formDescription }}</Typography>
            <form id="mktoForm_1001"></form>
            <div id="confirmFormCTWEngage" style="display: none">
                <strong>Thank you!</strong>
                <p>Check your email for details on your request.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    formContextData: {
        type: Object,
    },
    formDescription: {
        type: String,
        default:
            'Receive emails for public events and announcements of new research from the Carnegie Global Order and Institutions Program.',
    },
    scrollOut: {
        type: Boolean,
        default: false
    }
});
const { locale } = useLocale();

function formAmStatEn() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1001, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        if (props.scrollOut) {
            form.vals({ Site_Source__c: 'Scrollout GlobalOrderProgram Subscribe Mkto En' });
        }
        else {
            form.vals({ Site_Source__c: 'GlobalOrderProgram Subscribe Mkto En' });
        }
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
            form.addHiddenFields({ programAffiliation: 'globalOrder' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
onMounted(() => {
    formAmStatEn();
});
</script>

<style lang="scss" scoped>
@import './././assets/styles/core/marketoForms';
</style>
